html {
  overflow-x: hidden;
}
h1,
h2,
h3 {
  color: $darkblue;
}
section {
  padding: 100px 10px 60px;
  width: 100vw;
  &:nth-child(1) {
    padding: 200px 10px 60px;
  }
}
#ihr-goldhändler-in-wels .wrapper {
  display: grid;
  .cta {
    text-align: center;
    margin-top: 20px;
    a {
      text-decoration: none;
      color: #000;
      border: 1px solid rgb(39, 39, 39);
      padding: 18px 24px;
      font-size: 16px;
    }
  }
  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    h1,
    hr {
      grid-column: 1/3;
    }
    p:nth-child(3) {
      grid-column: 1/2;
    }
    p:nth-child(4) {
      grid-column: 2/3;
    }
    p:nth-child(5) {
      grid-column: 1/3;
    }
  }
}
#wissenswertes-über-gold {
  .wrapper {
    display: grid;
    gap: 30px;
    h3 {
      font-size: 24px;
    }
    @media (min-width: 768px) {
      grid-template-columns: 300px 1fr;
      p:nth-child(2) {
        font-size: 22px;
        text-align: center;
      }
      p,
      h1,
      hr {
        grid-column: 1/3;
      }
      p:nth-child(5),
      p:nth-child(7) {
        font-size: 16px;
        grid-column: 2/3;
      }
    }
  }
  * {
    color: $dgold;
  }
  background: $darkblue;
  p {
    color: $white;
  }
}
#produkte .wrapper {
  display: grid;
  gap: 20px;
  @media (min-width: 768px) {
    & {
      grid-template-columns: 1fr 1fr;
      gap: 50px 20px;
    }
    p,
    h1,
    hr {
      grid-column: 1/3;
    }
    h2,
    img {
      margin-top: 30px;
    }
    p:nth-child(3) {
      grid-column: 1/2;
      grid-row: 3/7;
    }
    #edelmetallbarren,
    p:nth-child(5),
    p:nth-child(6),
    p:nth-child(7) {
      grid-column: 2/3;
    }
    #goldgeschenkkarten,
    p:nth-child(9),
    p:nth-child(10) {
      grid-column: 1/2;
    }
    p:nth-child(11) {
      grid-column: 2/3;
      grid-row: 7/10;
    }
  }
}
#a04-tauschen {
  background: $darkblue;
  padding: 100px 20px;
  .wrapper {
    display: grid;
    gap: 30px;
    text-align: center;
    justify-content: center;
    svg {
      width: 50px;
      margin: auto;
      path {
        fill: $dgold;
      }
      circle {
        fill: transparent;
      }
    }
    #tauschen-sie-jetzt-ihr-geld-gegen-gold {
      color: $white;
      font-size: 38px;
      strong {
        color: $dgold;
      }
    }
  }
}
.far {
  font-size: 40px;
}
.fa-money-bill-alt:before {
  font-size: 40px;
  color: $dgold;
  content: "e964";
}
#goldentwicklung {
  .wrapper {
    display: grid;
    max-width: 800px;
    justify-content: center;
    text-align: center;
    font-size: 22px;
  }
}
.site_footer {
  background: $darkblue;
  color: #fff;
  svg {
    width: 30px;
  }
  a {
    color: $white;
    text-decoration: none;
  }
  .wrapper {
    .contacts {
      padding: 100px 20px;
      display: grid;
      gap: 30px;
      .content {
        display: grid;
        gap: 20px;
        p {
          display: grid;
          grid-template-columns: 60px 1fr;
          align-items: center;
        }
      }
    }
    .footer_bottom {
      text-align: center;
      display: flex;
      gap: 20px;
      padding: 0 0 30px;
      justify-content: center;
      justify-items: center;
      flex-wrap: wrap;
      &,
      a {
        color: $dgold;
      }
    }
  }
}
.site_page {
  margin-left: auto;
  margin-right: auto;
  max-width: 1020px;
  padding: 200px 20px 80px;
  h1,
  h2,
  h3 {
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 15px;
  }
  li {
    padding-left: 20px;
    margin-bottom: 8px;
  }
  h1 {
    font-size: 24px;
  }
  h2 {
    font-size: 22px;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 18px;
  }
  p, a {
    font-size: 1rem;
  }
}
