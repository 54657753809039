.page_header {
  position: fixed;
  top: 0;
  padding: 0;
  width: 100%;
  height: max-content;
  top: 0;
  right: 0;
  left: 0;
  background-color: $darkblue;
  z-index: 998;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px 2px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  .container {
    width: 99%;
    /* display: flex;
    flex-direction: column;
    justify-content: space-between; */
  }
  .logo img {
    width: 200px;
    height: auto;
  }
}
nav ul {
  list-style-type: none;
  li {
    padding: 5px;
    a {
      display: block;
      color: $dgold;
      font-weight: 300;
      letter-spacing: -0.4px;
      padding: 13px 0;
      border-bottom: 1px solid #bbb;
      transition: 0.4s;
      font-size: 20px;
      text-decoration: none;
      text-align: center;
      &:hover {
        color: $lgold;
      }
      i {
        font-size: 18px;
        position: relative;
        top: 0;
        padding-right: 5px;
      }
    }
  }
}
@media (min-width: 1024px) {
  .page_header {
    .container {
      flex-direction: row;
      display: flex;
      max-width: 1400px;
      margin-left: auto;
      margin-right: auto;
      justify-content: space-evenly;
    }
  }
  nav ul {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
    li a {
      border-bottom: none;
      font-size: 20px;
      font-family: sans-serif;
    }
  }
}
.hamburger {
  position: fixed;
  top: 116px;
  right: 40px;
  display: inline-block;
  transition-property: opacity, filter;
  transition-duration: 0.5s;
  transition-timing-function: linear;
  cursor: pointer;
  padding: 5px;
  margin-top: 4px;
  @media (min-width: 375px) {
    top: 80px;
  }
  .hamburger-box {
    width: 20px;
    height: 14px;
    display: inline-block;
    position: relative;
  }
}
.hamburger .hamburger-inner {
  display: block;
  top: 1px;
  margin-top: -1px;
}
.hamburger .hamburger-inner,
.hamburger .hamburger-inner::before,
.hamburger .hamburger-inner::after {
  width: 20px;
  height: 2px;
  background-color: $dgold;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.5s;
  transition-timing-function: ease;
}
.hamburger .hamburger-inner::before,
.hamburger .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger .hamburger-inner::before {
  top: 6px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.5s;
}
.hamburger .hamburger-inner::after {
  top: 12px;
}
.hamburger.opened .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-2.8571428571px, -4px, 0);
  opacity: 0;
}
.hamburger.opened .hamburger-inner::after {
  transform: translate3d(0, -12px, 0) rotate(-90deg);
}
.hamburger.opened .hamburger-inner {
  transform: translate3d(0, 6px, 0) rotate(45deg);
}
@media (max-width: 1023px) {
  .hidden_small {
    display: none;
  }
}
@media (min-width: 1024px) {
  .hamburger {
    display: none;
  }
}

.sl {
  font-family: "simple-line-icons" !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.sl-icon-map:before {
  content: "\e033";
}
.sl-icon-list:before {
  content: "\e067";
}
.sl-icon-check:before {
  content: "\e080";
}
.sl-icon-envelope-open:before {
  content: "\e01e";
}
nav ul li a.button.with-icon {
    background: #0cbcb7;
    padding: 9px 20px;
    border-radius: 50px;
    border: 1px solid transparent;
    color: #fff !important;
    transition: 0.3s;
    display: inline-block;
    font-size: 15px;
    font-weight: 500 !important;
}

/////////////////// top-wr /////////////////////
.quotes {
    margin-top: 0px;
    width: 100%;
    margin-left: 0px;
    position: unset;
    left: 0;
    top: 0;
    background-image: -webkit-gradient(linear, left top, right top, from(#c5a861), to(#988050));
    background-image: linear-gradient(
90deg, #c5a861, #988050);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    color: #fff;
    font-size: 13px;
    line-height: 23px;
    text-align: center;
    vertical-align: middle;
    padding: 3px 20px;
}
.quotes #gold-price {
    display: inline-block;
    vertical-align: middle;
}
.quotes #quotes-block, .quotes .price-block, .quotes .price-block li {
    display: inline-block;
    vertical-align: middle;
    margin: 0 5px;
    line-height: 100%;
    /* position: relative; */
}
.quotes #gold-price h5 {
    color: #fff;
    font-weight: 400;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin: 10px 0;
}
.quotes ul {
    margin: 0;
}
.quotes .price-block li {
    font-size: 14px;
    text-transform: uppercase;
    color: #d4cdbd;
}
.quotes .price-block .value {
    color: white;
}
.quotes .price-block li span {
    color: #fff;
    font-weight: bold;
}
