img {
  max-width: 100%;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.wrapper {
  width: 99%;
  max-width: 1400px;
  margin-right: auto;
  margin-left: auto;
}
* {
  font-family: sans-serif;
}
p {
  font-size: 1rem;
}
h1 {
  font-size: 27px;
  color: $darkblue;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.4;
  letter-spacing: 0px;
  padding: 20px;
  @media (min-width: 768px) {
    font-size: 60px;
  }
}
h2 {
  font-size: 22px;
}
hr {
  height: 3px;
  background: $dgold;
  border: none;
  width: 60%;
  margin: 25px auto;
}
