$darkblue: #031442;
$white: #fff;
$dgold: darkgoldenrod;
$lgold: gold;

html {
  background-color: #f2f2f2;
  color: #808080;
  font-family: "Helvetica",Verdana,Arial,sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.45;
  padding: 0;
  word-wrap: break-word;
  * {
    box-sizing: border-box;
  }
}